<template>
  <v-row id="review">
    <a
      target="_blank"
      id="review-link"
      href="https://www.google.com/maps/place/Tsukigo+-+During+Japanese/@43.6007032,1.4328424,12z/data=!4m7!3m6!1s0xf9c593574b2993:0x1ebf713446cfb35b!8m2!3d43.6007032!4d1.4328424!9m1!1b1"
      ><v-icon medium>mdi-comment-check-outline </v-icon>
      Cliquez ici pour consulter les avis de mes élèves !
    </a>
  </v-row>
</template>

<script>
const axios = require("axios").default;

export default {
  methods: {
    avis() {
      console.log("hello");
      axios
        .get(
          "https://mybusiness.googleapis.com/v4/accounts/6137410421714174287/locations/05957519674139214072/reviews"
        )
        .then(function (response) {
          console.log(response.data);
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.headers);
          console.log(response.config);
        });
    },
  },
};
</script>

<style>
#review {
  text-align: center;
  justify-content: center;
}
#review-link {
  font-size: larger;
  width: fit-content;
  justify-self: center !important;
  white-space: normal;
  /* overflow: hidden; */
  text-overflow: clip; 
  text-decoration: none;
}
</style>
