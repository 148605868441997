<template>
  <div class="home">
    <HomeBanner />
    <SecondBanner />
    <Reviews />
    <PracticalInfos />
    <AboutMe />
    <CuteBanner />
    <ContactForm />
  </div>
</template>

<script>
import HomeBanner from "../components/HomeBanner";
import PracticalInfos from "../components/PracticalInfos.vue";
import SecondBanner from "../components/SecondBanner.vue";
import CuteBanner from "../components/CuteBanner.vue";
import ContactForm from "../components/ContactForm.vue";
import AboutMe from "../components/AboutMe.vue";
import Reviews from "../components/Reviews.vue";

export default {
  name: "Home",
  components: {
    HomeBanner,
    SecondBanner,
    PracticalInfos,
    CuteBanner,
    ContactForm,
    AboutMe,
    Reviews,
  },
};
</script>
<style>
.home {
  font-size: large;
}
.text-center {
  font-size: 0.875rem;
  font-family: Roboto, sans-serif;
}
</style>
