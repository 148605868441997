<template>
  <v-footer padless class="footer">
    <v-col class="text-center" cols="12">
      <v-btn
        download="Mentions légales Tsukigo"
        :href="pdfLink"
        width="fit-content"
        text
        >Mentions légales</v-btn
      >
      2022 — Développé par Sarah Brisson
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    pdfLink: require("@/assets/MENTIONS_LEGALES.pdf"),
  }),
};
</script>

<style>
.footer {
  background-color: #f2f7ff;
}
</style>
