<template>
  <div class="banner" id="contact" align-content="center">
    <h2 id="contact">Contactez-moi !</h2>
    <v-row>
      <p id="contact-description">
        N'oubliez pas de préciser dans votre message votre âge, votre niveau de
        japonais, et vos disponbilités.
      </p>
    </v-row>
    <v-row class="important" justify="space-around" id="contact-description">
      <v-col>
        <v-icon large color="white">mdi-at</v-icon>
        tsukigo.japonais@gmail.com
      </v-col>
      <v-col>
        <v-icon large color="white">mdi-cellphone</v-icon>
        06 73 11 71 59
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <a
          href="https://www.instagram.com/tsukigo_japonais/?hl=en"
          target="_blank"
        >
          <v-img
            :src="require('@/assets/instagram.png')"
            width="100%"
            max-width="45px"
            />
        </a>
      </v-col>
      <v-col>
        <a href="https://www.tiktok.com/@tsukigo_japonais" target="_blank">
          <v-img
            :src="require('@/assets/tik_tok.webp')"
            max-width="40"
            width="100%"
          />
        </a>
      </v-col>
    </v-row>
    <!-- <v-row>
      Merci de préciser dans votre message votre âge, votre niveau de japonais,
      si vous souhaitez des cours en ligne ou en présentiel, seul ou en groupe.
    </v-row> -->
    <!-- <v-form
      class="middle-text"
      ref="form"
      @submit.prevent="sendEmail"
      id="check-login-form"
    >
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-text-field label="Nom" required></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Email" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field label="Téléphone"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Adresse"></v-text-field>
          </v-col>
        </v-row>
        <v-text-field label="Objet" required></v-text-field>
        <v-textarea
          outlined
          name="input-7-4"
          label="Rédigez votre message ici"
          required
        ></v-textarea>
        <v-btn depressed type="submit" form="check-login-form">Envoyer</v-btn>
      </v-container>
      <v-input type="submit" value="Send"/>
    </v-form> -->
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  methods: {
    mounted() {
      emailjs.init("YOUR_PUBLIC_KEY");
    },
    sendEmail() {
      console.log("here");
      console.log(this.$refs.form);
      emailjs
        .sendForm(
          "service_de507gg",
          "template_la4l7ie",
          this.$refs.form,
          "3tKjKyrHxFjPNjxpx"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
  },
};
</script>

<style>
#contact {
  background-color: #6683af !important;
  color: white;
  margin-block: 50px 20px;
  padding-bottom: 20px;
}
.important {
  font-style: bold;
  font-size: x-large;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}

#contact-description {
  max-width: 800px;
  padding: 20px;
}
</style>
