<template>
  <v-app id="app">
    <v-app-bar app color="#B2C9FF" dark>
      <v-app-bar-nav-icon
        v-if="!largeScreen"
        @click="drawer = !drawer"
        color="black"
      />
      <v-btn href="#app" text>
        <v-img
          :src="require('@/assets/logo-tsukigo-horizontal_nuage-go.png')"
          width="188px"
          alt="Home"
        />
        <!-- <v-icon color="black" x-large>mdi-weather-night</v-icon>
        <v-toolbar-title id="title" color="black">TSUKIGO</v-toolbar-title> -->
      </v-btn>

      <v-spacer></v-spacer>
      <div v-if="largeScreen" class="link-btn">
        <v-btn
          v-for="(item, index) in items"
          :key="index"
          :href="item.route"
          class="btn"
          text
          x-large
          rounded
          ><span id="black-text">{{ item.title }}</span></v-btn
        >
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="right" temporary fixed>
      <v-img
        :src="require('@/assets/logo-tsukigo-horizontal.png')"
        width="188px"
        alt="Home"
      />
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :href="item.route"
          @click="navigate(item)"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <!-- <router-view></router-view> -->
      <Home />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Home from "./pages/Home.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Home,
    Footer,
  },
  computed: {
    largeScreen() {
      return !this.$vuetify.breakpoint.mobile;
    },
  },
  data() {
    return {
      drawer: false,
      items: [
        { title: "Accueil", icon: "mdi-home", route: "#app" },
        {
          title: "Infos pratiques",
          icon: "mdi-information",
          route: "#infos-pratiques",
        },
        { title: "À propos de moi", icon: "mdi-account", route: "#about-me" },
        { title: "Contact", icon: "mdi-phone", route: "#contact" },
      ],
    };
  },
  methods: {
    navigate(item) {
      this.drawer = false;
      this.$router.push(item.route);
    },
  },
};
</script>
<style>
@font-face {
  font-family: "Avenir Lt std roman";
  src: url("../public/fonts/Avenir LT Std 55 Roman.otf");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

#app {
  background-color: #f2f7ff;
  overflow-x: hidden;
}
.btn {
  margin-left: 20px;
}
.link-btn {
  display: flex;
  flex-direction: row;
}
#title {
  color: black;
  text-indent: 1rem;
}
#black-text {
  color: black;
  font-size: medium;
}
h1 {
  color: #9d71e8;
  font-weight: bold;
  padding: 10px;
  font-size: xx-large;
}
h2 {
  color: #9d71e8;
}
h3 {
  color: #9d71e8;
}
p {
  margin-bottom: 0 !important;
}
</style>
