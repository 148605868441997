<template>
  <v-row class="banner" align-content="space-around" id="cute-banner">
    <v-row class="middle-text">
      <v-col cols="4" contain="true">
        <p>Apprenez à votre rythme !</p>
        <v-img
          src="../assets/bunny-reading.png"
          max-height="240"
          width="auto"
          :contain="true"
        />
      </v-col>
      <v-col cols="4">
        <p>Seul.e ou à plusieurs</p>
        <v-img src="../assets/friends.png" max-height="240" />
      </v-col>
      <v-col cols="4">
        <p>Atteignez vos objectifs !</p>
        <v-img src="../assets/happy.png" max-height="240" />
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "CuteBanner",
  props: {},
};
</script>

<style>
  #cute-banner {
    margin-block: 10px 10px;
  }
</style>
