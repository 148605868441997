<template>
  <div class="banner" id="second-banner">
    <h1>Un apprentissage personnalisé</h1>
    <div class="middle-text">
      <p>
        Enseignante indépendante, je propose un apprentissage personnalisé en ligne. Il vous permettra d’acquérir ou
        de consolider vos bases et de progresser vers un japonais courant.
      </p>
      <p>
        Mes cours vont du niveau grand débutant au niveau intermédiaire (JLPT
        N3 / L3 LLCER).
      </p>
      <p>
        Un voyage ? Un projet professionnel ? Une licence à valider ? <br />Je
        m'adapte à vos besoins et objectifs !
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondBanner",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#second-banner {
  padding-block: 30px;
  z-index: 1;
}
p {
  padding-block: 10px;
}
</style>
