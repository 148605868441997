<template>
  <div id="about-me">
    <h1 id="infos-title">À propos de moi</h1>
    <v-row
      id="about-me"
      align-content="center"
      justify-content="space-around"
    >
      <v-col class="d-flex justify-center align-center">
        <img
          src="../assets/juliette.jpg"
          max-width="500px"
          alt="Photo de Juliette"
          id="img-juliette"
        />
      </v-col>
      <v-col align-self="center">
        <p>
          Je m’appelle Juliette, j’ai 28 ans et je suis originaire de Toulouse.
          J’enseigne le japonais en indépendante depuis plus de trois ans.
        </p>
        <p>
          Après un bac option japonais et un stage de langue à Kyoto, j'ai
          obtenu une licence LLCER Japonais mention très bien à l'université
          Paris 7 Diderot. J'y ai étudié la langue mais aussi la culture, la
          littérature et l'histoire japonaises.
        </p>
        <p>
          J'ai ensuite effectué un séjour de 6 mois à l'université d'Ochanomizu
          à Tokyo.
        </p>
        <p>J'ai obtenu le JLPT N2, ce qui correspond à un niveau C1.</p>
        <br />
        <h3 id="infos-title">Mes cours</h3>
        <p>
          Durant mes études, j’ai souvent été surprise par la différence entre
          le japonais académique et celui du quotidien. J'ai donc créé des
          supports de cours clairs, riches en exemples, avec du vocabulaire et
          des expressions utiles et courantes.
        </p>
        <p>
          Les leçons de grammaire sont toutes rédigées par mes soins, ainsi
          qu'une grande partie des exercices. J'utilise également des supports
          variés et adaptés à vos envies. Je propose aussi des fiches de
          vocabulaire personnalisées.
        </p>
        <p>
          J'essaye toujours d'allier des cours efficaces et structurés avec de
          l'humour et de la bonne humeur !
        </p>
      </v-col>
    </v-row>
    <v-row align-content="center" justify="center" id="about-me">
      <div id=text-infos>Téléchargez un aperçu de mes cours !</div>
      <v-btn
        elevation="4"
        download="Cours de japonais - Tsukigo"
        :href="pdfLink"
        color="#6071d7"
        medium
        id="download-btn"
        ><v-icon>mdi-download</v-icon></v-btn
      >
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AboutMe",
  props: {},
  data: () => ({
    pdfLink: require("../assets/tsukigo.pdf"),
  }),
};
</script>

<style>
#about-me {
  padding-block: 10px 30px;
  margin-inline: 10px 10px;
  flex-direction: row;
  flex-wrap: wrap;
}
#text-infos {
  font-style: italic;
  text-align: center;
  width: fit-content;
  align-self: center;
}
#download-btn {
  color: white;
  font-size: larger;
  letter-spacing: normal;
  width: fit-content;
}
#img-juliette {
  max-height: 600px;
  padding: 20px;
}
</style>
